<template>
  <div class="intro full">
    <van-nav-bar title="景区相册">
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="ab_content">
      <div class="img_out cur_p" v-for="(item,index) in imgs" :key="index" @click="seeImg(index)">
        <img :src="item" alt />
      </div>
      <!--<van-image-preview :startPosition="startPosition" v-model="show" :images="imgs"></van-image-preview>-->
    </div>
  </div>
</template>

<script>
  import  { NavBar, Icon, ImagePreview} from 'vant'
  export default {
    name: "album",
    components:{
      'van-nav-bar': NavBar,
      // 'van-image-preview': ImagePreview,
      'van-icon': Icon,
    },
    data() {
      return {
        apis: {
          scenic: "/seller/find" //??????
        },
        startPosition: 0,
        show: false,
        scenic: {}, //????
        imgs: [] //??????
      };
    },
    mounted() {
      this.loadScenic();
    },
    created(){

    },
    methods: {
      seeImg(index) {
        this.startPosition = index;
        this.show = true;
        ImagePreview({
          images: this.imgs,
          startPosition: index,
          closeable: true,
          closeIcon: 'cross',
        });
      },
      exit() {
        // this.$destroy('KeepAlive')
        sessionStorage.clear();
        sessionStorage.setItem('LOGIN_STATUS',2);
        sessionStorage.setItem('token',this.$global.token);
        this.$router.go(-1);
      },
      //??????
      loadScenic() {
        let me = this;
        me.scenic = {};
        me.imgs = [];
        me.$get({
          url: me.apis.scenic,
          params: {
            sellerId: me.$route.query.key,
            token: me.$global.token
          }
        }).then(rsp => {
          me.scenic = rsp;
          if (rsp && rsp.attachments && rsp.attachments.length > 0) {
            var files = rsp.attachments;
            for (var i = 0, n = files.length; i < n; i++) {
              if (files[i].fileType && files[i].fileType == 1) {
                me.imgs.push(files[i].url);
              } else if (files[i].fileType && files[i].fileType == 2) {
                me.videos.push(files[i].url);
              }
            }
          }
          if (me.imgs.length <= 0) {
            me.imgs.push(me.scenic.cover);
          }
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  .img_out {
    width: calc(50% - 5px);
    height: 105px;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 10px;
    letter-spacing: 0;
    word-spacing: 0;
    float: left;
    &:nth-child(even) {
      margin-left: 10px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .intro .van-nav-bar {
    border-bottom: none;
  }
  .intro {
    height: 100%;
  }
  .ab_content {
    padding: 56px 10px 10px 10px;
    background: rgba(247, 247, 247, 1);
    height: 100%;
    overflow-x: auto;
    box-sizing: border-box;
  }
</style>
